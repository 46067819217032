define('zenformatic-frontend/instance-initializers/i18n', ['exports', 'zenformatic-frontend/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        var i18n = application.lookup('service:i18n');

        var locales = i18n.get('locales');

        if (window.localStorage) {
            var language = localStorage.getItem('language');

            if (!language || !locales.includes(language)) {
                language = calculateLocale(locales);
                localStorage.setItem('language', language);
            }
        } else {
            language = calculateLocale(locales);
        }

        i18n.set('locale', language);
    }

    function calculateLocale(locales) {
        var language = navigator.language || navigator.userLanguage || _environment.default.i18n.defaultLocale;

        language = language.split('-')[0];

        return locales.includes(language.toLowerCase()) ? language : _environment.default.i18n.defaultLocale;
    }

    exports.default = {
        initialize: initialize
    };
});