define('zenformatic-frontend/routes/services/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        model: function model() {
            return this.get('store').findAll('service');
        },

        afterModel: function afterModel() {
            this.get('meta').update({
                title: this.get('i18n').t('menu.services') + ' - ' + this.get('i18n').t('home.meta_title'),
                description: this.get('i18n').t('services.meta_description'),
                'og:image': 'https://www.zenformatic.com/images/zenformatic-logo.svg',
                'locale': this.get('i18n.locale')
            });
        }
    });
});