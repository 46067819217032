define('zenformatic-frontend/components/main-menu', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        //didInsertElement() {
        //if($(window).width() < 500)  {
        //$('#company_menu_container').addClass('responsive_menu_container');
        //$('#company_menu').addClass('responsive_menu');
        //var carousel = $('#company_menu');
        //carousel.itemslide( {
        //} );
        //console.log(carousel.getActiveIndex());
        //console.log(carousel.getCurrentPos());
        //$('#menu_left').click(function() {
        //carousel.next();
        //});
        //$('#menu_right').click(function() {
        //carousel.previous();
        //});
        //carousel.on('changeActiveIndex', function(e) {
        //if(carousel.getActiveIndex() > 0) {
        //setTimeout(function() {
        //carousel.previous();
        //}, 500);
        //}
        //});
        //}
        //}
    });
});