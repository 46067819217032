define('zenformatic-frontend/components/language-switcher', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        i18n: Ember.inject.service(),

        currentLocale: Ember.computed('i18n.locale', function () {
            var i18n = this.get('i18n');
            return i18n.locale;
        }),

        locales: Ember.computed('i18n.locale', 'i18n.locales', function () {
            var i18n = this.get('i18n');
            var currentLocale = i18n.locale;
            return this.get('i18n.locales').map(function (loc) {
                return { id: loc, text: loc.toUpperCase(), isCurrent: loc === currentLocale };
            });
        }),

        actions: {
            setLocale: function setLocale(locale) {
                if (this.get('i18n.locales').includes(locale)) {
                    this.set('i18n.locale', locale);
                    localStorage.setItem('language', locale);

                    this.sendAction('reloadData');
                }
            }
        }
    });
});