define('zenformatic-frontend/components/contact-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ajax: Ember.inject.service(),
        i18n: Ember.inject.service(),
        actions: {
            submitAction: function submitAction() {
                function validateEmail(email) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email.toLowerCase());
                }
                var data = {
                    first_name: $("input[name='first_name']").val(),
                    last_name: $("input[name='last_name']").val(),
                    email: $("input[name='email']").val(),
                    occupation: $("input[name='occupation']").val(),
                    company_name: $("input[name='company_name']").val(),
                    message: $("textarea[name='message']").val()
                };
                $('#contact_result').addClass('callout');
                if (data.first_name.length == 0 || data.last_name.length == 0 || data.email.length == 0 || data.message.length == 0) {
                    $('#contact_result').html(this.get('i18n').t('contacts.fill_all_fields').toString());
                    $('#contact_result').addClass('warning');
                } else if (!validateEmail(data.email)) {
                    $('#contact_result').html(this.get('i18n').t('contacts.fill_email').toString());
                    $('#contact_result').addClass('warning');
                } else {
                    this.get('ajax').post('/contact', { data: data });
                    if (this.from_service) $('#contact_result').html(this.get('i18n').t('contacts.service_success').toString());else $('#contact_result').html(this.get('i18n').t('contacts.success').toString());
                    $('#contact_result').removeClass('warning');
                    $('#contact_result').addClass('success');
                    $('input').val('');
                    $('textarea').val('');
                }
            }
        }
    });
});