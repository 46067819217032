define('zenformatic-frontend/components/cookie-compliance', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            if (document.cookie.search('complied=yes') > -1) {
                $('#cookie_compliance').remove();
            }
        },

        actions: {
            cookiesComply: function cookiesComply() {
                document.cookie = "complied=yes";
                $('#cookie_compliance').remove();
            }
        }
    });
});