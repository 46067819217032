define('zenformatic-frontend/initializers/i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('model', 'i18n', 'service:i18n');
  }

  exports.default = {

    name: 'i18n',

    after: 'ember-i18n',

    initialize: initialize

  };
});