define('zenformatic-frontend/adapters/application', ['exports', 'ember-data', 'zenformatic-frontend/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONAPIAdapter.extend({

        i18n: Ember.inject.service(),

        namespace: Ember.computed('i18n.locale', function () {
            var i18n = this.get('i18n');
            return 'api/' + i18n.locale;
        }),

        host: _environment.default.host

        //    shouldBackgroundReloadRecord: function(){ return false; }
    });
});