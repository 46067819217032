define('zenformatic-frontend/routes/services/view', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        model: function model(params) {
            return this.store.findRecord('service', params.service_slug);
        },

        afterModel: function afterModel(model) {
            this.get('meta').update({
                title: model.get('title') + ' - ' + this.get('i18n').t('menu.services') + ' - ' + this.get('i18n').t('home.meta_title'),
                description: model.get('short_text'),
                'og:image': 'https://www.zenformatic.com/images/zenformatic-logo.svg',
                'locale': this.get('i18n.locale')
            });
            $('.service').css('height', '100%');
        },
        actions: {
            willTransition: function willTransition(transition) {
                this.controller.set('will_sign_up', false);
            }
        }
    });
});