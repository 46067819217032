define('zenformatic-frontend/transitions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {
        var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 600;

        //this.transition(
        //this.matchSelector('#logo-liquid'),
        //this.use('toRight')
        //),
        this.transition(this.fromRoute('index'), this.toRoute('services'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('index'), this.toRoute('about'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('index'), this.toRoute('careers'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('index'), this.toRoute('blog'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('index'), this.toRoute('contacts'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('services'), this.toRoute('about'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('services'), this.toRoute('careers'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('services'), this.toRoute('blog'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('services'), this.toRoute('contacts'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('about'), this.toRoute('careers'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('about'), this.toRoute('blog'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('about'), this.toRoute('contacts'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('careers'), this.toRoute('blog'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('careers'), this.toRoute('contacts'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('careers'), this.toRoute('blog'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('blog'), this.toRoute('contacts'), this.use('toLeft'), this.reverse('toRight')), this.transition(this.fromRoute('services.index'), this.toRoute('services.view'), this.use('explode', {
            matchBy: 'data-service-id',
            use: ['explode', {

                pickOld: '.button',
                use: ['toUp', { duration: duration }]

            }, {
                use: ['fly-to', { duration: duration }]
            }]
        }, {
            pickNew: '#text_container',
            use: ['toUp', { duration: duration }]
        }, {
            pickNew: '#price_info',
            use: ['toRight', { duration: duration }]
        }, {
            pickNew: '#sign_up_button',
            use: ['toLeft', { duration: duration }]
        }, {
            use: ['toUp', { duration: duration }]
        }), this.reverse('explode', {
            matchBy: 'data-service-id',
            use: ['fly-to', { duration: duration / 2 }]

        }, {
            use: ['toDown', { duration: duration / 2 }]
        }));
    };
});