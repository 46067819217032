define('zenformatic-frontend/routes/services', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel() {},

        actions: {
            reloadData: function reloadData() {
                this.refresh();
            }
        }
    });
});