define('zenformatic-frontend/controllers/services/view', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        will_sign_up: false,
        actions: {
            sign_up: function sign_up() {
                this.set('will_sign_up', !this.will_sign_up);
            }
        }
    });
});