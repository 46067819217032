define('zenformatic-frontend/components/zenformatic-logo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    //    didRender() {
    //        this._super(...arguments);
    //        document.getElementById("logo-object").addEventListener("load", function() {
    //            var doc = this.getSVGDocument();
    //            var draw = SVG.adopt(doc.getElementById('svgto_we'));
    //            var master = SVG.adopt(doc.getElementById('zen_master'));
    //            var gradient = draw.gradient('radial', function(stop) {
    //              stop.at(0, '#7b287c')
    //              stop.at(1, '#140d57')
    //            });
    //            master.fill(gradient);
    //            gradient.from(0.63,0.48).to(0.63,0.48).radius(0.5);
    //            function heartpump (gradient, j) {
    //                for(var i = 0; i < 4; i++) {
    //                    gradient.animate(1000).radius(1.5).animate(1000).radius(0.5);
    //                    console.log('pump');
    //                }
    //                if(j % 3 == 0) {
    //                    gradient.animate(1200).radius(1.5);
    //                }
    //            }
    //            function heartloop(gradient, i) {
    //                console.log('heartlooping');
    //                if(i < 1000) {
    //
    //                    //if(i % 6 != 0)
    //                       // heartpump(gradient, i);
    //
    //                    //window.setTimeout(function() {heartloop(gradient, i + 1); }, 12000);
    //                }
    //            }
    //            //heartloop(gradient, 1);
    //            //gradient.animate(3000).radius(0.4);
    //        });
    //    }

  });
});