define('zenformatic-frontend/initializers/ember-data-slug', ['exports', 'ember-data-slug/initializers/ember-data-slug'], function (exports, _emberDataSlug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberDataSlug.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _emberDataSlug.initialize;
    }
  });
});